import React from 'react'
import TeacherChat from '../../components/TeacherChatComponent/TeacherChat'

function TeacherMessages() {
  return (
    <div>
    <TeacherChat/>
    </div>
  )
}

export default TeacherMessages