import React from 'react'
import Chat from '../../components/StudentChatComponent/Chat'

function StudentMessages() {
  return (
    <div>
    <Chat/>
    </div>
  )
}

export default StudentMessages